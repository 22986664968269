import React from "react"

import Layout from "../components/layout"
import './index.scss'

const IndexPage = () => (
  <Layout>
    <div class="content-top">
      <h1>Alles Gute zu deinem Geburtstag!</h1>
      <p class="content-center">
        Dein Geburtstagsgeschenk ist dieser Teil der Welt (Word Wide Web) - du kannst ab jetzt immer auf www.gsodam.eu gehen und hier kannst du in der Zukunft alles machen was du willst.
        Und auch eine tolle E-Mail Adresse wie z.b. pia@gsodam.eu verwenden.
      </p>
    </div>
    <div class="pyro">
      <div class="before"></div>
      <div class="after"></div>
    </div>
    <div class="box">
      <div class="head">
        <div class="head-top"></div>
        <div class="ear-left"></div>
        <div class="ear-right"></div>
        <div class="horn-left"></div>
        <div class="horn-right"></div>
        <div class="head-bottom">
          <div class="eye-left"><div class="pupil"></div></div>
          <div class="eye-right"><div class="pupil"></div></div>
          <div class="nose">
            <div class="nostril-left"></div>
            <div class="nostril-right"></div>
          </div>
          <div class="face-bottom">
            <div class="mouth-1"></div>
            <div class="mouth-2"></div>
            <div class="mouth-3"></div>
          </div>
        </div>
      </div>
      <div class="neck">
        <div class="spot"></div>
        <div class="spot"></div>
        <div class="spot"></div>
        <div class="spot"></div>
        <div class="spot"></div>
        <div class="spot"></div>
        <div class="spot"></div>
        <div class="spot"></div>
        <div class="spot"></div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
